import { useState } from "react";
import { createGlobalStore } from "hox";

function useAnnouncement() {
  const [refresh, setRefresh] = useState<Function>(() => () => {});
  const [refreshing, setRefreshing] = useState(false);
  return {
    refresh,
    setRefresh,
    refreshing,
    setRefreshing,
  };
}

export const [useAnnouncementStore] = createGlobalStore(useAnnouncement);
