import React, { useEffect, useState } from "react";
import {
  Heading,
  VStack,
  Image,
  HStack,
  CircularProgress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Flex,
  Icon,
  Center,
  Text,
} from "@chakra-ui/react";
import { AiFillWarning } from "react-icons/ai";
import {
  ExternalLinkIcon,
  TriangleUpIcon,
  TriangleDownIcon,
} from "@chakra-ui/icons";
import { useRequest } from "ahooks";
import { useAnnouncementStore } from "../store/Announcement";
/* Slider */
//import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import Slider from "react-slick";

const logos = {
  EAST: `${process.env.PUBLIC_URL}/images/e-nexco.png`,
  CENTER: `${process.env.PUBLIC_URL}/images/c-nexco.png`,
  WEST: `${process.env.PUBLIC_URL}/images/w-nexco.png`,
  TOKYO: `${process.env.PUBLIC_URL}/images/tokyo.jpg`,
  NAGOYA: `${process.env.PUBLIC_URL}/images/nagoya.png`,
};
const areaHash = {
  WEST: "西日本",
  CENTER: "中日本",
  EAST: "東日本",
  TOKYO: "首都高速",
  // FRONTで名古屋を非表示にするだけなら、ここをコメントアウトするだけでOK
  //NAGOYA: "名古屋高速", //20230814 夏季は名古屋停止、冬季(12/1)に復活する
  // e.g.
  // 雪氷期：12/1〜3/31 （表示期間）
  // 暖候期：4/1〜11/30
};
interface Announcement {
  id: number;
  title: string;
  url: string;
  area: "WEST" | "CENTER" | "EAST" | "TOKYO" | "NAGOYA";
  fromDate: Date; // 20230908 add レイアウト変更
  updatedAt: Date; // 20230908 add レイアウト変更
}
interface AnnouncementWithTitle {
  title: string;
  list: Announcement[];
}
// 20230908 add レイアウト変更 ----
interface AnnouncementSortedList {
  name: string;
  fdt: number;
  udt: number;
  list: Announcement[];
}
// ---- 20230908 add レイアウト変更
declare global {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  interface Window {
    gtag: (
      key: string,
      trackingId: string,
      // eslint-disable-next-line camelcase
      config: { link_url: string },
    ) => void;
  }
}

export function AnnouncementList() {
  const [reqErr, setReqErr] = useState(false);  // 20240603 mod
  const { data, loading, refresh } = useRequest<AnnouncementWithTitle, []>(
    async (): Promise<AnnouncementWithTitle> =>
      // 20240603 mod
      //fetch("/api/announcement/withtitle").then((x) => x.json()),
      await fetch("/api/announcement/withtitle") 
      .then((response) => {
        if (!response.ok) {
          console.info("AnnouncementWithTitle Fetch Error")
          setReqErr(true)
          return {title:"", list:[]}
        }else{
          setReqErr(false)
          return response.json()
        }
      }),
    {
      pollingInterval: 180 * 1000,
      pollingWhenHidden: false,     // false: ページが非表示のときにポーリングが一時的に停止し、ページが再び表示されるときに再開
    }
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [area, setArea] = useState("");
  /* Slider */
  // const sliderRef = useRef<Slider>(null);
  const { setRefresh } = useAnnouncementStore();

  useEffect(() => {
    setRefresh(() => refresh);
  }, [refresh, setRefresh]);

  // 20230908 add レイアウト変更 ----
  const initMaxTitleLen = 74; // iPhoneSEを基準にして入り切る文字数
  const [maxTilteShowWord, setMaxTilteShowWord] = useState(initMaxTitleLen);
  const [showRowCount, setShowRowCount] = useState(0); // 表示対象(会社)の数
  const [isAllShow, setIsAllShow] = useState(false); // 初期値は一部表示状態
  const [isEnable, setIsEnable] = useState(true); // 一覧表示トグルボタン：初期値は有効状態
  const CustomerAllCount = Object.keys(areaHash).length;
  const ListRowHeight = 96; // １行分の高さ（90px）
  const mkHeightCssString = (r: number) => {
    return ListRowHeight * r + "px";
  };
  const ListMaxHeightCssString = mkHeightCssString(CustomerAllCount); // リストの最大表示エリア高(px)
  const [listHeightStr, setListHeightStr] = useState(ListMaxHeightCssString);
  // お知らせ情報のある会社の数
  useEffect(() => {
    const getCurrentShowListCount = () => {
      let res = CustomerAllCount;
      if (data) {
        let tmpCounter = 0;
        for (let key in areaHash) {
          const filtered = data.list.filter(
            (d: { area: string }) => d.area === key,
          );
          if (filtered.length > 0) {
            tmpCounter++;
          }
        }
        res = tmpCounter;
      }
      return res;
    };
    const cnt = getCurrentShowListCount();
    setShowRowCount(cnt);

    //console.info('useEffect: ' + cnt)
    setListHeightStr(mkHeightCssString(cnt));

    if (cnt === 0) {
      setIsEnable(false); // トグルボタンを無効にする
    } else {
      // いずれかの会社でデータが存在する

      // 等幅フォントではないので、おおよその値になるよ
      const elTitle = document.getElementsByClassName("title_element");
      if (elTitle.length > 0) {
        const style = window.getComputedStyle(elTitle[0]);
        const fontsize = parseInt(style.fontSize);
        const width = parseInt(style.width);
        const mojisuu = Math.floor(width / fontsize) * 4; // ４行分
        //console.info("f: " + fontsize + " w: " + width + " cnt: " + mojisuu)
        setMaxTilteShowWord(mojisuu);
      } else {
        setMaxTilteShowWord(initMaxTitleLen);
      }

      if (cnt === CustomerAllCount) {
        // 全社がデータありの場合、全社表示にする
        setIsAllShow(true); // 全社表示中（上向き）状態にする
        setIsEnable(false); // トグルボタンを無効にする
      } else {
        setIsAllShow(false); // 一部表示中（下向き）状態にする
        setIsEnable(true); // トグルボタンを有効にする
      }
    }
  }, [CustomerAllCount, data]);
  // ---- 20230908 add レイアウト変更

  const linkText = (url: string, title: string, area: string) => {
    const linkEventName = "CLICK_" + area;
    return url ? (
      // eslint-disable-next-line
      <a
        target="_blank"
        href={url}
        rel="noopener"
        onClick={() => {
          window.gtag("event", linkEventName, { link_url: url });
        }}
      >
        {title} <ExternalLinkIcon mx="2px" />
      </a>
    ) : (
      title
    );
  };

  if (loading) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }

  // 20240603 mod
  if(!data) return (<></>)
    // if (typeof data === "undefined") {
    //   return null;
    // }

  const handleOnClose = () => {
    /* Slider */  
    // sliderRef.current?.slickPlay();
    onClose();
  };
  //https://react-slick.neostack.com/docs/api
  /* Slider */  
  // 固定の会社順番で、横展開表示 (old)
  // const mkAreaInfo = () => {
  //   var settings = {
  //     dots: false,
  //     infinite: true,
  //     speed: 500,
  //     autoplay: true,
  //     autoplaySpeed: 3000, // default: 3000 自動スクロールする間隔(ms)
  //     slidesToShow: 4, // default: 1 何件見せるか件数を指定
  //     //slidesToScroll: 1,  // default: 1 何件ずつスライドさせるか件数を指定
  //     swipeToSlide: true, //default:false   drag/swipeでslidesToScrollの設定を無視するならtrue
  //     //touchThreshold: 5,  // default: 5
  //     //focusOnSelect: false,
  //   };

  //   return (
  //     <Box mt="0" mb="0" ml="5" mr="5" pb="1">
  //       {" "}
  //       {/* dots: true, > pb="50" */}
  //       <Box
  //         sx={{
  //           ".slick-dots": {
  //             transform: "translateY(15px)", //"translateY(1em)"
  //           },
  //           ".slick-dots li": {
  //             marginLeft: "13px",
  //             marginRight: "13px",
  //           },
  //           ".slick-dots li button": {
  //             _before: {
  //               transition: "0.2s",
  //               content: "''",
  //               borderRadius: "100%",
  //               background: "gray.500", //"cyan.500"
  //             },
  //           },
  //           ".slick-arrow": {
  //             backgroundColor: "rgba(255,255,255,0.5)", //"cyan.500"
  //             //color: "red",   //"white"
  //             w: "30px",
  //             h: "50px",
  //             transition: "0.2s",
  //             _hover: {
  //               backgroundColor: "rgba(255,255,255,0.5)", //"cyan.500"
  //               color: "white",
  //             },
  //             _focus: {
  //               backgroundColor: "rgba(255,255,255,0.5)", //"cyan.500"
  //               color: "white",
  //             },
  //             _before: {
  //               transition: "0.2s",
  //             },
  //           },
  //           ///* //20230814 夏季は名古屋停止、冬季に復活する(2/2)
  //           ".slick-prev": {
  //             left: "-25px", //-40px
  //             _before: {
  //               color: "red",
  //               content: '"◀"',
  //             },
  //           },
  //           ".slick-next": {
  //             right: "-25px",
  //             _before: {
  //               color: "red",
  //               content: '"▶"',
  //             },
  //           },
  //         }}
  //       >
  //         <Slider ref={sliderRef} {...settings}>
  //           {(Object.keys(areaHash) as Array<keyof typeof areaHash>).map(
  //             (i, key) => {
  //               const filtered = data.list.filter((d) => d.area === i);
  //               return (
  //                 <HStack key={key}>
  //                   <VStack
  //                     //key={key}
  //                     onClick={() => {
  //                       if (filtered.length > 0) {
  //                         sliderRef.current?.slickPause();
  //                         setArea(i);
  //                         onOpen();
  //                       }
  //                     }}
  //                   >
  //                     <Image
  //                       src={logos[i]}
  //                       boxSize="22vw"
  //                       objectFit="contain"
  //                     />
  //                     <Heading className="AnnouncenetCount">
  //                       {filtered.length}件
  //                     </Heading>
  //                   </VStack>
  //                 </HStack>
  //               );
  //             },
  //           )}
  //         </Slider>
  //       </Box>
  //     </Box>
  //   );
  // };

  // 20230908 add レイアウト変更 ----
  // 掲載開始時刻の会社順で、縦展開表示 (new: 20230911)
  const createAreaInfoRow = () => {
    // サーバー側でソート済み（掲載開始日：昇順、更新時刻：降順）
    // 最新情報順で表示するように、各社最新情報を取得し全社でソート、リストを再編成する
    // 1. 各社毎の最新情報（1件目）を取得
    let sortedList: AnnouncementSortedList[] = [];
    for (let key in areaHash) {
      const filtered = data.list.filter(
        (d: { area: string }) => d.area === key,
      );
      let tmpFD = 0;
      let tmpUD = 0;
      if (filtered.length > 0) {
        try {
          tmpFD = new Date(
            Date.parse(filtered[0].fromDate.toString()),
          ).getTime();
          tmpUD = new Date(
            Date.parse(filtered[0].updatedAt.toString()),
          ).getTime();
        } catch (error) {
          console.error("[Error Date parse]" + error);
        }
      }
      const tmpList = {
        name: key,
        fdt: tmpFD,
        udt: tmpUD,
        list: filtered,
      };
      sortedList.push(tmpList);
    }
    // 2. 全社でソート（掲載開始日：昇順、更新時刻：降順）
    sortedList.sort((a, b) => {
      // 掲載開始日：降順
      if (a.fdt !== b.fdt) {
        if (a.fdt < b.fdt) return 1;
        if (a.fdt > b.fdt) return -1;
      }
      // 更新時刻：降順
      if (a.udt !== b.udt) {
        if (a.udt < b.udt) return 1;
        if (a.udt > b.udt) return -1;
      }
      return 0;
    });
    //console.info(sortedList)

    // 3. 表示順リストを生成
    let sortedAreaHash: { [key: string]: string } = {};
    for (let i = 0; i < sortedList.length; i++) {
      if (sortedList[i].list.length > 0) {
        sortedAreaHash[sortedList[i].name] =
          sortedList[i].list.length.toString();
      } else {
        sortedAreaHash[sortedList[i].name] = "0";
      }
    }
    //console.info(sortedAreaHash)

    const maxLength = 75; //上限文字数（全角文字）: iPhoneSEを基準
    const imgBaseWidthCssStr = "75px"; // '90px'
    const textWidthCssStr = `calc(100% - ${imgBaseWidthCssStr});`;
    const textBaseHeightCssStr = "85px";

    const applyTextLimit = (w: string, cnt: number) => {
      // 全角文字を1,半角文字を0.5として文字数をカウント
      function strCount(str: string) {
        let len = 0;
        for (let i = 0; i < str.length; i++) {
          if (str[i].match(/[ -~]/)) {
            len += 0.5;
          } else {
            len += 1;
          }
        }
        return len;
      }

      if (w.length === 0) {
        w = "発表されている情報はありません。";
      }

      //return w  // そのまま
      //return (w.length > maxLength) ? w.substring(0, maxLength) + '...' : w;  // 単純に文字数
      const eStrLen = strCount(w);
      //return eStrLen > maxLength ? w.substring(0, maxLength) + "..." : w;     // 全半角考慮し固定数
      return eStrLen > maxTilteShowWord
        ? w.substring(0, maxTilteShowWord) + "..."
        : w; // Elementの幅より
    };

    return (Object.keys(sortedAreaHash) as Array<keyof typeof areaHash>).map(
      (i, key) => {
        const filtered = data.list.filter((d) => d.area === i);
        let otherItemCount = 0;
        let title = "";
        let colorCss = "#000";
        let textBaseLineHeightCssStr = "1.4em";
        if (filtered.length > 0) {
          title = filtered[0].title;
          otherItemCount = filtered.length - 1;
        } else {
          // ０件の場合
          colorCss = "#7a7a7a";
          textBaseLineHeightCssStr = textBaseHeightCssStr;
        }

        return (
          <HStack
            key={key}
            ml={1}
            mr={1}
            h={`${ListRowHeight}px`}
            borderBottom={"1px"}
            borderColor={"#efefef"}
            alignItems={"flex-start"}
            onClick={() => {
              if (filtered.length > 0) {
                setArea(i);
                onOpen();
              }
            }}
          >
            <VStack m={0} p={0} spacing="0px">
              <Image
                src={logos[i]}
                boxSize={imgBaseWidthCssStr}
                objectFit="contain"
              />
              <Box
                position={"relative"}
                top={"3px"}
                fontSize="12.8px" // 0.8em  // Defualt: 1em = 16px
                lineHeight={"16px"} // 1.0em
                color={"#AE0100"} //6b0f09 //c70f02
              >
                {otherItemCount === 0 ? <></> : `(他 ${otherItemCount} 件)`}
              </Box>
            </VStack>
            <Box mt={"4px"} w={textWidthCssStr}>
              <Box
                className="title_element"
                height={textBaseHeightCssStr}
                lineHeight={textBaseLineHeightCssStr} // default: 1.5em
                fontSize="16px" // 16px | 14.4px
                color={colorCss}
                overflow={"hidden"}
                //text-overflow: ellipsis;
                textOverflow={"ellipsis"}
                //display: -webkit-box;
                //-webkit-line-clamp: 3;
              >
                {applyTextLimit(title, otherItemCount)}
              </Box>
            </Box>
          </HStack>
        );
      },
    );
  };
  const mkAreaInfo_new = () => {
    // 一列に表示する為、会社の数でロゴサイズを変更する
    // 4社の場合: "22vw"
    // 5社の場合: "17vw"
    let imgSizeStr = "22vw";
    if (Object.keys(areaHash).length === 5) {
      imgSizeStr = "17vw";
    }

    return (
      <>
        {showRowCount === 0 ? (
          <>
            <HStack justify={"center"}>
              {(Object.keys(areaHash) as Array<keyof typeof areaHash>).map(
                (i, key) => {
                  const filtered = data.list.filter((d) => d.area === i);
                  return (
                    <Image
                      key={key}
                      src={logos[i]}
                      boxSize={imgSizeStr}
                      objectFit="contain"
                    />
                  );
                },
              )}
            </HStack>
            <Box
              mb={3}
              textAlign={"center"}
              fontSize="17.6px" // 1.1em
              fontWeight={"bold"}
            >
              現在、発表されている情報はありません。
            </Box>
          </>
        ) : (
          <Box h={listHeightStr} overflow={"hidden"} transition="height .25s">
            {createAreaInfoRow()}
          </Box>
        )}
      </>
    );
  };
  const announceListShowHideButton = () => {
    let displayCssStr = "block";

    if (!isEnable) {
      displayCssStr = "none";
    }

    const heighValue = "30px";
    return (
      <Box
        display={displayCssStr}
        bgColor={"#AE0100"}
        textAlign={"center"}
        h={heighValue}
        lineHeight={heighValue}
        w="100%"
        onClick={() => {
          // isAllShow(true): 現在全件表示中=>絞り込み表示へ
          // isAllShow(false): 現在絞り込み表示中=>全件表示へ
          isAllShow
            ? setListHeightStr(mkHeightCssString(showRowCount))
            : setListHeightStr(ListMaxHeightCssString);

          setIsAllShow(!isAllShow); // Show | Hide
        }}
      >
        {
          // ChevronDownIcon, ChevronUpIcon | ArrowDownIcon, ArrowUpIcon
          isAllShow ? (
            <TriangleUpIcon boxSize={6} color={"#fff"} />
          ) : (
            <TriangleDownIcon boxSize={6} color={"#fff"} />
          )
        }
      </Box>
    );
  };
  // ---- 20230908 add レイアウト変更

  // 20240603 add
  if(reqErr) {
    return (
      <Center h={"70vh"}>
        <VStack color="#000" alignItems="center" spacing={3}>
          <Text fontSize="16px" fontWeight="700">
            ページを表示できませんでした。
          </Text>
          <Text fontSize="16px" fontWeight="700">
            ご迷惑をおかけして申し訳ありませんが、
          </Text>
          <Text fontSize="16px" fontWeight="700">
            しばらく時間をおいてから
          </Text>
          <Text fontSize="16px" fontWeight="700">
            再度アクセスしてください。
          </Text>
        </VStack>
      </Center>
    )
  }
  
  return (
    <>
      <Flex
        h="60px"
        w="100%"
        background="#AE0100"
        justifyContent="center"
        alignItems="center"
      >
        <Heading color="white">
          {" "}
          {/* size="lg" */}
          {/*重要なお知らせ*/}
          <HStack justify={"center"}>
            <Icon w={"35px"} h={"35px"} as={AiFillWarning}></Icon>
            <Box>{data.title}</Box>
          </HStack>
        </Heading>
      </Flex>

      {/* 20230908 add レイアウト変更----  */}
      {/* mkAreaInfo()*/}{/* ori */}
      {mkAreaInfo_new()}
      {announceListShowHideButton()}
      {/* ---- 20230908 add レイアウト変更 */}

      <Modal isOpen={isOpen} onClose={handleOnClose} isCentered>
        <ModalOverlay />
        <ModalContent width="80vw" height="60vh">
          <ModalHeader fontFamily="Kosugi Maru">お知らせ一覧</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow="scroll">
            {data.list
              .filter((d) => d.area === area)
              .map((i) => (
                <Box key={i.id} mb={2} bg="gray.50" borderRadius="sm">
                  {linkText(i.url, i.title, i.area)}
                </Box>
              ))}
            {/** 20230908 add レイアウト変更---- */}
            {data.list.filter((d) => d.area === area).length === 0 ? (
              <Box mt={3}>現在、発表されている情報はありません。</Box>
            ) : (
              <></>
            )}
            {/** ---- 20230908 add レイアウト変更 */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
