import { useState } from "react";
import { createGlobalStore } from "hox";

function useSnow() {
  const [refresh, setRefresh] = useState<Function>(() => () => {});
  const [refreshing, setRefreshing] = useState(false);
  return {
    refresh,
    setRefresh,
    refreshing,
    setRefreshing,
  };
}

export const [useSnowStore] = createGlobalStore(useSnow);
