import { Box, CircularProgress, Flex, Heading, Image } from "@chakra-ui/react";
import { useRequest } from "ahooks";
import { sortBy } from "lodash";
import React, { useEffect } from "react";
import { useSnowStore } from "../store/Snow";
import Slider from "react-slick";

interface SnowPicture {
  id: number;
  content: string;
  time: "T12" | "T24" | "T72";
}
interface SnowPictureWithTitle {
  title: string;
  list: SnowPicture[];
}

export function SnowForecast() {
  const { data, loading, refresh } = useRequest<SnowPictureWithTitle, []>(
    async (): Promise<SnowPictureWithTitle> =>
      // 20240603 mod
    //fetch("/api/snow-picture/withtitle").then((x) => x.json()),
    await fetch("/api/snow-picture/withtitle")  
    .then((response) => {
      if (!response.ok) {
        console.info("snow-picture Fetch Error")
        return {title:"", list:[]}
      }else{
        return response.json()
      }
    }),
  {
    pollingInterval: 180 * 1000,
    pollingWhenHidden: false,     // false: ページが非表示のときにポーリングが一時的に停止し、ページが再び表示されるときに再開 // 20240603 add
  },
);

  const { setRefresh } = useSnowStore();

  useEffect(() => {
    setRefresh(() => refresh);
  }, [refresh, setRefresh]);

  if (loading && !data) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }
  if (typeof data === "undefined") {
    return null;
  }
  var settings = {
    dots: true,
    infinite: false, //true
    speed: 500,
    autoplay: false,
    slidesToShow: 1, // default: 1 何件見せるか件数を指定
    slidesToScroll: 1, // default: 1 何件ずつスライドさせるか件数を指定
    arrows: false,
  };

  if (data && data.list.length > 0) {
    return (
      <>
        <Flex
          h="50px"
          w="100%"
          background="black"
          justifyContent="center"
          alignItems="center"
        >
          <Heading color="white">
            {data.title}
            {/*大雨影響範囲*/}
          </Heading>
        </Flex>
        <Box mt={2} mr={4} mb={6} ml={4}>
          <Slider {...settings}>
            {sortBy(data.list, "time").map((i, key) => (
              <Box key={i.id}>
                <Image src={i.content} alt="" ml="auto" mr="auto" />
              </Box>
            ))}
          </Slider>
        </Box>
      </>
    );
  } else {
    // 20240603 mod
    //return null;
    return (<></>);
  }
}
