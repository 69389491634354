import { Heading, Link, Text, VStack, Box } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export function PrivacyPolicy() {
  // Googleアナリティクスサービス利用規約のページ
  const url_gau =
    "https://marketingplatform.google.com/about/analytics/terms/jp/";
  // Googleポリシーと規約ページ
  const url_gap = "https://policies.google.com/technologies/ads?hl=ja";

  return (
    <VStack>
      <Box>
        <Heading fontSize="lg" mt={4}>
          個人情報の取得、利用及び提供に関して
        </Heading>
        <Text fontSize="mb" mb={4}>
          利用目的の達成に必要な範囲内で、適法かつ公正な手段により収集致します。
          個人情報を第三者に提供する場合には、事前に本人の同意を取り、目的外利用は致しません。
        </Text>
      </Box>
      <Box>
        <Heading fontSize="lg" mt={4}>
          Googleアナリティクスの利用について
        </Heading>
        <Text fontSize="mb" mb={4}>
          当サイトでは、サイトの利用状況を把握するために、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。
        </Text>
        <Text fontSize="mb" mb={4}>
          この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関しての詳細は
          <Link href={url_gau} isExternal ml="2" mr="2">
            Googleアナリティクスサービス利用規約のページ{" "}
            <ExternalLinkIcon mx="2px" />
          </Link>
          や
          <Link href={url_gap} isExternal ml="2" mr="2">
            Googleポリシーと規約ページ
            <ExternalLinkIcon mx="2px" />
          </Link>
          をご覧ください。
        </Text>
      </Box>
      <Box>
        <Heading fontSize="lg" mt={4}>
          その他
        </Heading>
        <Text fontSize="mb" mb={4}>
          本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
        </Text>
      </Box>
    </VStack>
  );
}
