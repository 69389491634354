import {
  Box,
  Center,
  ChakraProvider,
  extendTheme,
  Flex,
  Heading,
  HStack,
  Image,
  Button,
  Link,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";
import { AnnouncementList } from "./components/AnnouncementList";
import { SnowForecast } from "./components/SnowForecast";
import { TipList } from "./components/TipList";
import { useAnnouncementStore } from "./store/Announcement";
import { useSnowStore } from "./store/Snow";
import { useDrivingTipStore } from "./store/useDrivingTip";
import PullToRefresh from "react-simple-pull-to-refresh";
import {PartsLogoLink} from "./components/PartsLogoLink";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import "./App.css";

const theme = extendTheme({
  components: {
    Heading: {
      baseStyle: {
        fontFamily: "Kosugi Maru",
      },
    },
    Text: {
      baseStyle: {
        fontFamily: "Kosugi Maru",
      },
    },
  },
});

export default function Home() {
  const { refresh: refreshAnnouncement } = useAnnouncementStore();
  const { refresh: refreshSnow } = useSnowStore();
  const { refresh: refreshDrivingTip } = useDrivingTipStore();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleRefresh = async () => {
    await refreshAnnouncement();
    await refreshSnow();
    await refreshDrivingTip();
  };

  return (
    <ChakraProvider theme={theme}>
      <PullToRefresh onRefresh={handleRefresh}>
        <Box w="100vw">
          <Box position="sticky" top={0} zIndex={1} background="white">
            <AnnouncementList />
          </Box>
          <SnowForecast />
          <Flex
            h="50px"
            w="100%"
            background="black"
            justifyContent="center"
            alignItems="center"
          >
            <Heading color="white">渋滞・規制情報</Heading>
          </Flex>
          <PartsLogoLink />
          <TipList />
          <Flex
            h="30px"
            w="100%"
            background="#FFCDCE"
            justifyContent="center"
            alignItems="center"
          >
            <Text color="black" fontSize="12px" fontWeight="bold">
              「故障車」や「落下物」・道路の破損などを見つけたら
            </Text>
          </Flex>
          <Center>
            <HStack color="#DB6D6E" alignItems="center" spacing={3}>
              <Text fontSize="16px" fontWeight="700">
                道路緊急ダイヤル
              </Text>
              <Link href="tel:#9910" fontSize="24px" fontWeight="800">
                #9910
              </Link>
            </HStack>
          </Center>
        </Box>
      </PullToRefresh>
      <Center>
        <Box
          fontSize="14px"
          fontFamily="Kosugi Maru"
          onClick={() => {
            onOpen();
          }}
        >
          Privacy
        </Box>
      </Center>
      <Modal
        scrollBehavior="inside"
        size="xl"
        motionPreset="slideInBottom"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent height="90vh">
          <ModalHeader fontFamily="Kosugi Maru">
            プライバシーポリシー
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow="scroll">
            <PrivacyPolicy />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}
